<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CAlert color="warning" dismissible :show.sync="showPatientAlert" close-button>
            El paciente no tiene permisos para acceder a esta página.
            <CProgress :max="10" :value="showPatientAlert" height="3px" color="danger" animated />
          </CAlert>
        </CCol>
        <CCol md="8" class="login-image-container">
          <img src="@/../public/intrack.svg" width="200" alt="Intrack">
        </CCol>
        <CCol md="6">
          <CCard class="p-2">
            <CCardBody>
              <CForm class="login-form">
                <label for="user" class="text-label">Cuenta</label>
                <CInput
                  id="user"
                  ref="user"
                  placeholder="Nombre de usuario"
                  autocomplete="username email"
                  @keypress.enter="focusPasswd"
                  v-model="user"
                >
                  <template #prepend-content>
                    <CIcon name="cil-user" />
                  </template>
                </CInput>

                <label for="password" class="text-label">Contraseña</label>
                <CInput
                  id="password"
                  ref="passwd"
                  placeholder="Introduce la contraseña"
                  :type="togglePassword"
                  autocomplete="curent-password"
                  @keypress.enter="login"
                  v-model="password"
                >
                  <template #prepend-content>
                    <CIcon
                      :name="icon"
                      title="Clicar para mostrar u ocultar contraseña"
                      @click.native="toggleShowPasswd"
                    />
                  </template>
                </CInput>
                <CRow>
                  <CCol v-if="loading" class="loading-icon">
                    <CSpinner color="primary" />
                  </CCol>
                  <CCol v-else col="12" class="text-left">
                    <CButton class="px-4 link-button" @click="login()">Login</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="9" class="p-4">
          <CAlert color="danger" dismissible :show.sync="showErrorAlert" close-button>
            <strong>AVISO</strong> la contraseña o el usuario insertado son
            incorrectos
            <CProgress :max="10" :value="showErrorAlert" height="3px" color="danger" animated />
          </CAlert>
          <CAlert color="warning" dismissible :show.sync="showServerAlert" close-button>
            <strong>AVISO</strong> ha habido un error. Por favor, inténtelo de
            nuevo en unos minutos
            <CProgress :max="10" :value="showServerAlert" height="3px" color="danger" animated />
          </CAlert>
        </CCol>
      </CRow>
      <img class="img-logos" src="../assets/logos/logos.png" alt="Logos Combinados">
      <div class="footer-text">
        En el marco de la Convocatoria de Ayudas para proyectos de investigación en ciencia y tecnología aplicada a la actividad física beneficiosa para la salud (AFBS) y la medicina deportiva con cargo a los Fondos Europeos del Plan de Recuperación, Transformación y Resiliencia gestionada por el Consejo Superior de Deportes en su papel de institución de fomento (Plan de Recuperación, Transformación y Resiliencia – Financiado por la Unión Europea – NextGenerationEU), ha sido financiado el desarrollo del proyecto “InTrack - Intelligent tracking of articulations in recently operated athletes"
      </div>
    </CContainer>
  </div>
</template>

<script>
import { decodePayload } from "@/utils/token";
import { loginUser } from '@/services/login'

export default {
  name: "Login",
  data() {
    return {
      user: '',
      password: '',
      showErrorAlert: 0,
      showServerAlert: 0,
      showPatientAlert: 0,
      icon: "cil-lock-locked",
      togglePassword: "password",
      loading: false
    };
  },
  created() {
    localStorage.removeItem("token");
  },
  mounted() {
    this.$refs.user.$el.getElementsByTagName("input")[0].focus();
  },
  methods: {
    /**
     * Función para iniciar sesión.
     */
    login() {
      this.loading = true;
      loginUser(this.user, this.password).then(response => {
        localStorage.token = response.data.token;
        let user = decodePayload(localStorage.token);
          if(user.type === 'patient'){
              this.showPatientAlert = 10;
              this.loading = false;
          }else{
              window.location.href = "/";
          }
      }).catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
              this.showFailure(2);
              return;
            }
            if (
              error.response.status == 403 ||
              error.response.status == 400 ||
              error.response.status == 404
            ) {
              this.showFailure(1);
            } else {
              this.showFailure(2);
        }        
      });
    },
     /**
     * Función para mostrar el alert de error.
     */
    showFailure(number) {
      if (number == 1) {
        this.showErrorAlert = 10;
      } else {
        this.showServerAlert = 10;
      }
    },
    /**
     * Función para ejecutar focus al input password
     */
    focusPasswd() {
      this.$refs.passwd.$el.getElementsByTagName("input")[0].focus();
    },
    /**
     * Función para poder ver la contraseña
     */
    toggleShowPasswd() {
      if (this.icon === "cil-lock-locked") {
        this.icon = "cil-lock-unlocked";
        this.togglePassword = "text";
      } else {
        this.icon = "cil-lock-locked";
        this.togglePassword = "password";
      }
    }
  }
};
</script>
<style scoped>
.img-logos{
  width: 100%;
}
.footer-text {
  width: 100%;
    background-color: #f2f7f9;
    font-style: italic;
    text-align: center;
}
.c-app{
  background-color: #f2f7f9;
}
.login-image-container{
  text-align: center;
  padding: 100px 0px 30px 0px;
}
.login-image-container img{
  width: 500px;
  object-fit: contain;
  margin: 0 auto;
  text-align: center;
}

.link-button{
  width: 100%;
  text-transform: uppercase;
  padding: 10px 0;
  background-color: #1c5ef5;
}

form label{
  color: #7e7e7e;
  display: block;
}

.loading-icon {
  text-align: center;
}

@media (max-width: 768px) {
  .login-image-container img{
    width: 200px;
  }
}

.text-label {
  font-weight: bold;
  font-size: 16ox;
}
.login-image-container{
  padding: 0 0 30px 0;
}
</style>
